<template>
  <v-container
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
  >
    <v-col cols="12">
      <v-row
        class="statistics-box pb-4"
      >
        <v-col
          cols="12"
          class="d-flex align-center"
        >
          <span class="font-weight-bold">
            Сведения о лице, заполнившем декларацию
            <v-menu offset-x>
              <template #activator="{ on, attrs }">
                <v-icon
                  class="mx-1"
                  tabindex="-1"
                  small
                  dense
                  v-bind="attrs"
                  v-on="on"
                >mdi-tune</v-icon>
              </template>
              <v-list>
                <v-list-item> Поиск в справочнике </v-list-item>
              </v-list>
            </v-menu>
          </span>
        </v-col>
        <v-col
          cols="12"
          lg="1"
        >
          <label>
            Страна
            <v-autocomplete
              v-model="declarant_json.country_letter"
              auto-select-first
              outlined
              dense
              hide-details="auto"
              background-color="white"
              :items="countries"
              item-text="text"
              item-value="letterCode"
              class="mr-1"
              :filter="filterBySearchField"
            />
          </label>
        </v-col>
        <v-col
          cols="12"
          lg="2"
        >
          <label> Код вида документа </label>
          <v-autocomplete
            ref="identity_doc_name"
            v-model="declarant_json.identity_doc_name"
            :items="identityDocumentsFiltered"
            :filter="filterBySearchField"
            item-text="search"
            item-value="name"
            auto-select-first
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </v-col>
        <v-col
          cols="12"
          lg="2"
        >
          <label> Номер документа</label>
          <v-text-field
            ref="declarant_identity_doc_number"
            v-model="declarant.declarant_identity_doc_number"
            v-mask="declarant_json.country_letter == 'BY' ? 'SS#######' : 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'"
            outlined
            dense
            hide-details="auto"
            background-color="white"
            @input="(v) => (declarant.declarant_identity_doc_number = v.toLocaleUpperCase())"
          />
        </v-col>
        <v-col
          cols="12"
          lg="2"
        >
          <label> Дата выдачи</label>
          <custom-date-picker
            v-model="declarant_json.identity_doc_date_on"
            :visited.sync="fields_been_visited"
            :has-changes.sync="hasChanges"
          />
        </v-col>
        <v-col
          cols="12"
          lg="2"
        >
          <label> Личный номер</label>
          <v-text-field
            ref="personal_number"
            v-model="declarant_json.personal_number"
            v-mask="declarant_json.country_letter == 'BY' ? '#######S###SS#' : 'XXXXXXXXXXXXXXXXXXXX'"
            outlined
            dense
            hide-details="auto"
            background-color="white"
            @input="(v) => (declarant_json.personal_number = v.toLocaleUpperCase())"
            @change="checkIsForbiddenPerson"
          />
        </v-col>
        <v-col
          cols="12"
          lg="3"
        >
          <label>Сведения о визе</label>
          <div style="display: inline-flex">
            <v-text-field      
              v-model="declarant_json.visa_number"
              outlined
              :label="'Номер'"
              dense
              hide-details="auto"
              style="width: 152px; margin-right: 25px;"
              :disabled="disabled"
              :background-color="disabled ? 'grey lighten-2' : 'white'"
            />
            <custom-date-picker
              v-model="declarant_json.visa_date"
              :label="'Дата'"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
              style="width: 152px"
              :disabled="disabled"
              :background-color="disabled ? 'grey lighten-2' : 'white'"
            />
          </div>
        </v-col>
        <!--<v-col
          cols="12"
          lg="2"
        >
          <label>Дата истечения срока</label>
          <custom-date-picker
            v-model="declarant_json.identity_doc_date_off"
            :visited.sync="fields_been_visited"
            :has-changes.sync="hasChanges"
          />
        </v-col>-->
        
        <v-col
          cols="12"
          lg="2"
        >
          <label>Фамилия</label>
          <v-text-field
            v-model="declarant.declarant_last_name"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </v-col>
        <v-col
          cols="12"
          lg="2"
        >
          <label>Имя</label>
          <v-text-field
            v-model="declarant.declarant_first_name"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </v-col>
        <v-col
          cols="12"
          lg="2"
        >
          <label>Отчество</label>
          <v-text-field
            v-model="declarant.declarant_middle_name"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </v-col>
        <v-col
          cols="12"
          lg="2"
        >
          <label>Дата рождения</label>
          <custom-date-picker
            v-model="declarant.declarant_date_birth"
            :visited.sync="fields_been_visited"
            :has-changes.sync="hasChanges"
          />
        </v-col>
        <v-col
          v-if="!contacts.length"
          class="mt-6"
          cols="12"
          lg="3"
        >
          <v-btn
            dense
            class="elevation-0"
            @click="addContactItem"
          >
            <v-icon>mdi-plus</v-icon>
            Контакт
          </v-btn>
        </v-col>
        <v-col
          v-else
          cols="12"
          lg="4"
        >
          <single-contact
            v-for="(contact, index) in contacts"
            :ref="'cont_' + index"
            :key="index"
            :types="contact_types"
            :item="contact"
            :idx="index"
            :contacts-length="contacts.length"
            @deleteContactItem="deleteContactItem"
            @addContactItem="addContactItem"
            @contactsUpdater="contactsUpdater"
          />
        </v-col>
        <!--  -->
        <v-col
          cols="12"
          class="pb-0"
        >
          <div class="text-body-1 font-weight-bold">
            Адрес
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="1"
        >
          <label>Индекс</label>
          <v-text-field
            v-model="declarant_json.postal_index"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </v-col>
        <v-col
          cols="12"
          lg="3"
        >
          <label>Область/Район</label>
          <v-text-field
            v-model="declarant_json.region"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </v-col>
        <v-col
          cols="12"
          lg="2"
        >
          <label>Город/Деревня</label>
          <v-text-field
            v-model="declarant_json.city"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </v-col>
        <v-col
          cols="12"
          lg="4"
        >
          <label>Улица</label>
          <v-text-field
            v-model="declarant_json.street_house"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </v-col>
        <v-col
          cols="12"
          lg="1"
        >
          <label>Дом</label>
          <v-text-field
            v-model="declarant_json.house"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </v-col>
        <v-col
          cols="12"
          lg="1"
        >
          <label>Ном. помещ.</label>
          <v-text-field
            v-model="declarant_json.room"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </v-col>
        <!--  -->
        <v-col
          cols="12"
          class="pb-0"
        >
          <div class="text-body-1 font-weight-bold">
            Адрес регистрации
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="2"
        >
          <label>
            Страна
            <v-autocomplete
              v-model="declarant_json.temp_country_letter"
              auto-select-first
              outlined
              dense
              hide-details="auto"
              background-color="white"
              :items="countries"
              item-text="text"
              item-value="letterCode"
              class="mr-1"
              :filter="filterBySearchField"
            />
          </label>
        </v-col>
        <v-col
          cols="12"
          lg="1"
        >
          <label>Индекс</label>
          <v-text-field
            v-model="declarant_json.temp_postal_index"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </v-col>
        <v-col
          cols="12"
          lg="2"
        >
          <label>Область/Район</label>
          <v-text-field
            v-model="declarant_json.temp_region"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </v-col>
        <v-col
          cols="12"
          lg="2"
        >
          <label>Город/Деревня</label>
          <v-text-field
            v-model="declarant_json.temp_city"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </v-col>
        <v-col
          cols="12"
          lg="3"
        >
          <label>Улица</label>
          <v-text-field
            v-model="declarant_json.temp_street_house"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </v-col>
        <v-col
          cols="12"
          lg="1"
        >
          <label>Дом</label>
          <v-text-field
            v-model="declarant_json.temp_house"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </v-col>
        <v-col
          cols="12"
          lg="1"
        >
          <label>Ном. помещ.</label>
          <v-text-field
            v-model="declarant_json.temp_room"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import {filterBySearchFieldMixin} from "@/mixins/catalogs"
import CustomDatePicker from "@/components/shared/custom-date-picker.vue"
import blockAutoUpdate from "@/mixins/block-auto-update.mixin"
import {getCountryNameByCode, getIdentityCodeByName} from "@/helpers/catalogs"
import cloneDeep from 'lodash.clonedeep'
import SingleContact from './single-contact.vue'
import {checkIsForbidden} from "@/helpers/risks";

export default {
  components: {CustomDatePicker, SingleContact},
  mixins: [filterBySearchFieldMixin, blockAutoUpdate],
  data: () => ({
    block_id: "passenger-filler",
    addition: false,
    promises: [],
    declarant: {
      declaration_id: null,
      declarant_date_birth: "",
      declarant_first_name: "",
      declarant_identity_doc_number: "",
      declarant_last_name: "",
      declarant_middle_name: ""
    },
    declarant_json: {
      city: "",
      contacts: [],
      country_letter: "",
      country_name: "",
      identity_doc_date_off: "",
      identity_doc_date_on: "",
      identity_doc_name: "",
      identity_doc_code: "",
      personal_number: "",
      postal_index: "",
      region: "",
      room: "",
      house: "",
      street_house: "",
      visa_number: "",
      visa_date: "",
      temp_city: "",
      temp_country_letter: "",
      temp_country_name: "",
      temp_postal_index: "",
      temp_region: "",
      temp_room: "",
      temp_house: "",
      temp_street_house: "",
    },
    about_money: {
      money_exists: ""
    },
    contacts: []
  }),
  computed: {
    ...mapGetters({
      selected: "ptd/getSelected",
      countries: "catalogs/getNsiCountries",
      contact_types: "catalogs/getContactTypes",
      identityDocuments: "catalogs/getNsiIdentityDocuments"
    }),
    identityDocumentsFiltered() {
      if (this.declarant_json.country_letter) {
        if (['BY', 'RU', 'KZ', 'KG', 'AM'].includes(this.declarant_json.country_letter)) {
          return this.identityDocuments.filter(item => {
            return item.code.startsWith(this.declarant_json.country_letter)
          })
        }
        return this.identityDocuments.filter(item => {
          return item.code.startsWith('XX')
        })
      }
      return this.identityDocuments
    },
    disabled() {
      return !this.about_money.money_exists
    }
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    },
    "about_money.money_exists": {
      handler(value) {
        if (value == false){
          this.declarant_json.visa_number = null;
          this.declarant_json.visa_date = null; 
          this.uploadData(); 
        }
      }
    }
  },
  methods: {
    checkIsForbiddenPerson(identity) {
      checkIsForbidden.call(this, {
        identity,
        isPerson: true,
        promises: this.promises,
        dataModel: "declarant_json.personal_number",
      })
    },
    deleteContactItem(index) {
      this.contacts.splice(index, 1)
      this.readyToUpdate()
    },
    addContactItem() {
      this.contacts.push({
        code: '',
        name: '',
        number: ''
      })
      this.readyToUpdate()
    },
    contactsUpdater(payload) {
      this.contacts[payload.index] = payload.cont_item
      this.readyToUpdate()
    },
    getContactsWithName() {
      return this.contacts.map(item => {
        const {name} = this.contact_types.find(i => i.code === item.code) ?? {}
        return {
          ...item,
          name,
        }
      })
    },
    readyToUpdate() {
      this.hasChanges = true,
      this.fields_been_visited = true
    },
    setFields() {
      const declarant = this.selected.declarant;
      const declarant_json = this.selected.declarant.declarant_json;
      const about_money = this.selected.about_money;

      const contacts = declarant_json?.contacts ?? []
      this.contacts = cloneDeep(contacts)

      Object.keys(this.declarant).forEach((key) => {
        if (key in declarant) {
          this.declarant[key] = declarant[key];
        }
      });

      Object.keys(this.declarant_json).forEach((key) => {
        if (key in declarant_json) {
          this.declarant_json[key] = declarant_json[key];
        }
      });

      Object.keys(this.about_money).forEach((key) => {
        if (key in about_money) {
          this.about_money[key] = about_money[key];
        }
      });
    },
    uploadData() {
      return Promise.all(this.promises).then(() => {
        const declarant = this.convertEmptyStringsToNull({
          ...this.declarant,
          declarant_json: {
            ...this.declarant_json,
            country_name: getCountryNameByCode(this.countries, this.declarant_json.country_letter),
            temp_country_name: getCountryNameByCode(this.countries, this.declarant_json.temp_country_letter),
            contacts: this.getContactsWithName(),
            identity_doc_code: getIdentityCodeByName(this.identityDocuments, this.declarant_json.identity_doc_name)
          },
        })
        return this.$store.dispatch("ptd/uploadBlockData", {
          name: "declarant",
          value: declarant,
        });
      })

    }
  }
};
</script>
