<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div
          :id="block_id"
          v-click-outside="triggerOnFocus"
          class="statistics-box pa-lg-4"
        >
          <v-row>
            <v-col
              cols="12"
              lg="8"
              class="pb-0"
            >
              <div class="font-weight-bold d-flex align-center mt-2">
                Физическое лицо, заполнившее (подписавшее) таможенный документ
                <v-menu offset-x>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      class="mx-1 mr-4"
                      tabindex="-1"
                      small
                      dense
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-tune
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item> Поиск в справочнике </v-list-item>
                    <v-list-item>
                      Заполнить из личного кабинета
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-col>
            <v-col
              cols="12"
              lg="4"
              class="d-none d-md-block"
            >
              <v-autocomplete
                v-model="customs_agent_json.role"
                auto-select-first
                outlined
                dense
                hide-details="auto"
                background-color="white"
                :items="agents"
                item-text="text"
                item-value="val"
                class="mr-1"
                @change="changeAgent"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              lg="2"
            >
              <label>
                Страна
                <v-autocomplete
                  v-model="customs_agent_json.country_letter"
                  auto-select-first
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  :items="countries"
                  item-text="text"
                  item-value="letterCode"
                  class="mr-1"
                  :filter="filterBySearchField"
                />
              </label>
            </v-col>
            <v-col
              cols="12"
              lg="2"
            >
              <label> Код вида документа </label>
              <v-autocomplete
                ref="identity_doc_name"
                v-model="customs_agent_json.identity_doc_name"
                :items="identityDocumentsFiltered"
                :filter="filterBySearchField"
                item-text="search"
                item-value="name"
                auto-select-first
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </v-col>
            <v-col
              cols="12"
              lg="2"
            >
              <label> Номер документа</label>
              <v-text-field
                v-model="customs_agent.customs_agent_identity_doc_number"
                v-mask="customs_agent_json.country_letter == 'BY' ? 'SS#######' : 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'"
                class="mr-1"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </v-col>
            <v-col
              cols="12"
              lg="2"
            >
              <label> Дата выдачи</label>
              <custom-date-picker
                v-model="customs_agent_json.identity_doc_date_on"
                :visited.sync="fields_been_visited"
                :has-changes.sync="hasChanges"
              />
            </v-col>
            <v-col
              cols="12"
              lg="4"
            />
            <!--<v-col
              cols="12"
              lg="2"
            >
              <label>Дата истечения срока</label>
              <custom-date-picker
                v-model="customs_agent_json.identity_doc_date_off"
                :visited.sync="fields_been_visited"
                :has-changes.sync="hasChanges"
              />
            </v-col>
            <v-col
              cols="12"
              lg="2"
            >
              <label> Личный номер</label>
              <v-text-field
                v-model="customs_agent_json.personal_number"
                v-mask="customs_agent_json.country_letter == 'BY' ? '#######S###SS#' : 'XXXXXXXXXXXXXXXXXXXX'"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </v-col>-->
            <v-col
              cols="12"
              lg="2"
            >
              <label>Фамилия</label>
              <v-text-field
                v-model="customs_agent.customs_agent_last_name"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </v-col>
            <v-col
              cols="12"
              lg="2"
            >
              <label>Имя</label>
              <v-text-field
                v-model="customs_agent.customs_agent_first_name"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </v-col>
            <v-col
              cols="12"
              lg="2"
            >
              <label>Отчество</label>
              <v-text-field
                v-model="customs_agent.customs_agent_middle_name"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </v-col>
            <v-col
              cols="12"
              lg="2"
              class="pr-8"
            >
              <label>Дата рождения</label>
              <custom-date-picker
                v-model="customs_agent.customs_agent_date_birth"
                :visited.sync="fields_been_visited"
                :has-changes.sync="hasChanges"
              />
            </v-col>
            <v-col
              v-if="!contacts.length"
              class="mt-6"
              cols="12"
              lg="3"
            >
              <v-btn
                dense
                class="elevation-0"
                @click="addContactItem"
              >
                <v-icon>mdi-plus</v-icon> Контакт
              </v-btn>
            </v-col>
            <v-col
              v-else
              cols="12"
              lg="4"
            >
              <single-contact
                v-for="(contact, index) in contacts"
                :ref="'cont_' + index"
                :key="index"
                :types="contact_types"
                :item="contact"
                :idx="index"
                :contacts-length="contacts.length"
                @deleteContactItem="deleteContactItem"
                @addContactItem="addContactItem"
                @contactsUpdater="contactsUpdater"
              />
            </v-col>
            <!--  -->
            <v-col
              cols="12"
              lg="12"
              class="pb-0"
            >
              <div class="font-weight-bold d-flex align-center mt-2">
                Сведения о таможенном представителе
                <v-menu offset-x>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      class="mx-1 mr-4"
                      tabindex="-1"
                      small
                      dense
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-tune
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item> Поиск в справочнике </v-list-item>
                    <v-list-item>
                      Заполнить из личного кабинета
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-col>
            <v-col
              cols="12"
              lg="3"
            >
              <label> Реестр таможенных представителей </label>
              <v-autocomplete
                ref="customs_agent_license_num"
                v-model="customs_agent_json.customs_agent_license_num"
                auto-select-first
                outlined
                dense
                hide-details="auto"
                background-color="white"
                :items="customs_agents"
                item-text="licenseNumber"
                item-value="licenseNumber"
                class="mr-1"
              />
            </v-col>
            <v-col
              cols="12"
              lg="3"
            >
              <label>
                Договор
                <v-text-field
                  v-model="customs_agent_json.customs_agent_contract_num"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="12"
              lg="2"
            >
              <label>
                Дата договора
                <custom-date-picker
                  v-model="customs_agent_json.customs_agent_contract_date"
                  :visited.sync="fields_been_visited"
                  :has-changes.sync="hasChanges"
                />
              </label>
            </v-col>
            <v-col
              cols="12"
              lg="2"
              offset="2"
            >
              <label>
                Аттестат тамож. представителя
                <v-text-field
                  v-model="customs_agent_json.certificate_number"
                  v-mask="'#####'"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <!--  -->
            <!--<v-col
              cols="12"
              lg="2"
            >
              <label>
                Наименование документа
                <v-text-field
                  v-model="customs_agent_json.auth_doc_code"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col> -->
            <v-col
              cols="12"
              lg="2"
            >
              <label>
                Номер документа
                <v-text-field
                  v-model="customs_agent_json.auth_doc_number"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="12"
              lg="2"
            >
              <label>
                Дата документа
                <custom-date-picker
                  v-model="customs_agent_json.auth_doc_date_on"
                  :visited.sync="fields_been_visited"
                  :has-changes.sync="hasChanges"
                />
              </label>
            </v-col>
            <!--<v-col
              cols="12"
              lg="2"
            >
              <label>
                Дата окончания полномочий
                <custom-date-picker
                  v-model="customs_agent_json.auth_doc_date_off"
                  :visited.sync="fields_been_visited"
                  :has-changes.sync="hasChanges"
                />
              </label>
            </v-col> -->
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import CustomDatePicker from "@/components/shared/custom-date-picker.vue";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { getCountryNameByCode, getIdentityCodeByName } from "@/helpers/catalogs"
import cloneDeep from 'lodash.clonedeep'
import SingleContact from './single-contact.vue'
import axios from 'axios'
import { AUTH } from '@/http-routes'

export default {
  components: { CustomDatePicker, SingleContact },
  mixins: [filterBySearchFieldMixin, blockAutoUpdate],
  data: () => ({
    block_id: "passenger-guarantor",
    customs_agent: {
      declaration_id: null,
      customs_agent_date_birth: "",
      customs_agent_first_name: "",
      customs_agent_identity_doc_number: "",
      customs_agent_last_name: "",
      customs_agent_middle_name: ""
    },
    customs_agent_json: {
      auth_doc_code: "",
      auth_doc_date_off: "",
      auth_doc_date_on: "",
      auth_doc_number: "",
      certificate_number: "",
      city: "",
      country_letter: "",
      country_name: "",
      customs_agent_contract_date: "",
      customs_agent_contract_num: "",
      customs_agent_license_num: "",
      house: "",
      identity_doc_code: "",
      identity_doc_date_off: "",
      identity_doc_date_on: "",
      identity_doc_name: "",
      personal_number: "",
      postal_index: "",
      region: "",
      room: "",
      role: "",
      street_house: ""
    },
    contacts: [],
    agents: [
      {text: 'Декларант (физическое лицо)', val: '1'},
      {text: 'Таможенный представитель', val: '2'},
      {text: 'Лицо, действующее от имени и по поручению декларанта', val: '3'}
    ]
  }),
  computed: {
    ...mapGetters({
      selected: "ptd/getSelected",
      countries: "catalogs/getNsiCountries",
      identityDocuments: "catalogs/getNsiIdentityDocuments",
      user: "auth/getUser",
      contact_types: "catalogs/getContactTypes",
      customs_agents: "catalogs/getNsiCustomsAgent"
    }),
    identityDocumentsFiltered(){
      if(this.customs_agent_json.country_letter){
        if( ['BY', 'RU', 'KZ', 'KG', 'AM'].includes(this.customs_agent_json.country_letter)){
          return this.identityDocuments.filter(item => {
            return item.code.startsWith(this.customs_agent_json.country_letter)
          })
        }
        return this.identityDocuments.filter(item => {
          return item.code.startsWith('XX')
        })
      }
      return this.identityDocuments
    }
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    },
  },
  methods: {
    // смена агента
    changeAgent(){
      // Декларант
      if(this.customs_agent_json.role === '1'){
        const declarant = this.selected.declarant
        const declarant_json = this.selected.declarant.declarant_json

        this.customs_agent = {
          ...this.customs_agent,
          customs_agent_first_name: declarant.first_name ,
          customs_agent_identity_doc_number: declarant.declarant_identity_doc_number ,
          customs_agent_last_name: declarant.declarant_last_name ,
          customs_agent_middle_name: declarant.declarant_middle_name ,
        }
        Object.keys(this.customs_agent_json).forEach((key) => {
          if (key in declarant_json) {
            this.customs_agent_json[key] = declarant_json[key];
          }
        });
        const contacts = declarant_json?.contacts ?? []
        this.contacts = cloneDeep(contacts)
        this.uploadData()
      }
      if(this.customs_agent_json.role === '2'){
        axios
          .get(`${AUTH}user/getUserPersonalData?userId=${this.user.user.id}`,)
          .then(({data}) =>{
            this.customs_agent = {
              ...this.customs_agent,
              customs_agent_date_birth: null,
              customs_agent_first_name: data.first_name ,
              customs_agent_identity_doc_number: `${data.user_doc_series}${data.user_doc_number}`,
              customs_agent_last_name: data.last_name ,
              customs_agent_middle_name: data.middle_name ,
            }
            this.customs_agent_json = {
              ...this.customs_agent_json,
              country_name: null,
              country_letter: null,
              identity_doc_code: data.user_doc_code,
              identity_doc_name: data.user_doc_name,
              identity_doc_date_off: data.user_doc_d_off,
              identity_doc_date_on: data.user_doc_d_on,
              auth_doc_code: data.uid_doc_name,
              auth_doc_date_off: data.uid_doc_d_off,
              auth_doc_date_on: data.uid_doc_d_on,
              auth_doc_number: data.uid_doc_number,
            }

            this.contacts = [{code: "TE", name: "ТЕЛЕФОН", number: data.uid_phone}]
            this.uploadData()
          })
      }
      if(this.customs_agent_json.role === '3'){

        Object.keys(this.customs_agent).forEach((key) => {
          this.customs_agent[key] = null
        })
        Object.keys(this.customs_agent_json).forEach((key) => {
          this.customs_agent_json[key] = null
        })
        this.customs_agent.declaration_id = this.selected.id

        this.contacts = []
        this.uploadData()

      }
    },
    // смена агента
    deleteContactItem(index){
      this.contacts.splice(index, 1)
      this.readyToUpdate()
    },
    addContactItem(){
      this.contacts.push({
        code: '',
        name: '',
        number: ''
      })
      this.readyToUpdate()
    },
    contactsUpdater(payload){
      this.contacts[payload.index] = payload.cont_item
      this.readyToUpdate()
    },
    getContactsWithName(){
      return this.contacts.map(item => {
        const {name} = this.contact_types.find(i => i.code === item.code) ?? {}
        return {
          ...item,
          name,
        }
      })
    },
    readyToUpdate(){
      this.hasChanges = true,
      this.fields_been_visited = true
    },
    setFields() {
      const customs_agent = this.selected.customs_agent;
      const customs_agent_json = this.selected.customs_agent.customs_agent_json;

      const contacts = customs_agent_json?.contacts ?? []
      this.contacts = cloneDeep(contacts)

      Object.keys(this.customs_agent).forEach((key) => {
        if (key in customs_agent) {
          this.customs_agent[key] = customs_agent[key];
        }
      });

      Object.keys(this.customs_agent_json).forEach((key) => {
        if (key in customs_agent_json) {
          this.customs_agent_json[key] = customs_agent_json[key];
        }
      });
    },
    uploadData() {
      const customs_agent = this.convertEmptyStringsToNull({
        ...this.customs_agent,
        customs_agent_json: {
          ...this.customs_agent_json,
          country_name: getCountryNameByCode(this.countries, this.customs_agent_json.country_letter) || null,
          contacts: this.getContactsWithName(),
          identity_doc_code: getIdentityCodeByName(this.identityDocuments, this.customs_agent_json.identity_doc_name) || null
        },
      })
      return this.$store.dispatch("ptd/uploadBlockData", {
        name: "customs_agent",
        value: customs_agent,
      });
    }
  },
};
</script>
